import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import getGoToHealthTabTool from "./getGoToHealthTabTool";
import getGoToServiceRecommendationsTabTool from "./getGoToServiceRecommendationsTabTool";
import getGoToServiceRecordsTabTool from "./getGoToServiceRecordsTabTool";
import getGoToServiceScheduleTabTool from "./getGoToServiceScheduleTabTool";
import getGoToTimelineTabTool from "./goToTimelineTab/getGoToTimelineTabTool";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the signal event analytics agent.
 */
const getTools = (params: DuckGraphParams): StructuredTool[] => {
  // We deliberately do not initialize the page handler with a route value because
  // we do not yet conclusively know what the route would be because we don't have
  // a VIN yet. We could try to extract the VIN from the pathname, but that would
  // severly limit the utility of the agent because it would be unable to navigate
  // to different VIN's. Instead, we will set the route value later, after the agent
  // has provided a VIN. The agent will be able to get the VIN from the pathname,
  // or from the user's utterance.
  const pageHandler = new PageHandler();

  return [
    getGoToHealthTabTool(pageHandler),
    getGoToServiceRecordsTabTool(pageHandler),
    getGoToServiceScheduleTabTool(pageHandler),
    getGoToServiceRecommendationsTabTool(pageHandler),
    getGoToTimelineTabTool(
      pageHandler,
      params.availableData.vinView?.sensorOptions,
      params.availableData.vinView?.timelineChartOptions,
      params.currentState.vinView?.selectedTimelineChartOptions,
      params.currentState.vinView?.selectedSensorsAndTriggers,
      params.currentState.vinView?.selectedDateRange,
      params.currentState.vinView?.selectedSignalEventFilters
    ),
  ];
};

export default getTools;

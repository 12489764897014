import {
  getCaptureScreenshotAgentNode,
  getClaimAnalyticsAgentNode,
  getClaimAnalyticsAgentToolNode,
  getDocumentRetrievalNode,
  getRagAgentNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getSignalEventAnalyticsAgentNode,
  getSignalEventAnalyticsAgentToolNode,
  getSupervisorAgentNode,
  getVinViewAgentNode,
  getVinViewAgentToolNode,
} from "duck/graph/nodes";
import { graphState } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { Runnable } from "@langchain/core/runnables";
import { END, MemorySaver, START, StateGraph } from "@langchain/langgraph/web";

import { GenericToolNodeName, getNextNode, NodeNames } from "./utils";

/**
 * @summary Get DUCK's compiled state graph.
 * @param params The parameters for the agent from the UI
 * @param withMemory True to use the memory saver, false to not use memory at all
 * @returns The compiled state graph
 */
const getGraph = async (
  params: DuckGraphParams,
  withMemory: boolean = false
): Promise<Runnable> => {
  // The MemorySaver checkpointer is not intended for production usage
  const checkpointer = withMemory ? new MemorySaver() : undefined;

  /**
   * Define the state graph for the agent.
   * @see graph.md
   */
  const stateGraph = new StateGraph(graphState)
    .addNode(NodeNames.DOCUMENT_RETRIEVAL, getDocumentRetrievalNode(params))
    .addNode(NodeNames.SUPERVISOR, await getSupervisorAgentNode(params))
    .addNode(NodeNames.REJECT_CLARIFY, await getRejectClarifyNode(params))
    .addNode(NodeNames.RAG, await getRagAgentNode(params))
    .addNode(
      NodeNames.CLAIM_ANALYTICS,
      await getClaimAnalyticsAgentNode(params)
    )
    .addNode(
      NodeNames.CLAIM_ANALYTICS_TOOLS,
      getClaimAnalyticsAgentToolNode(params)
    )
    .addNode(
      NodeNames.SIGNAL_EVENT_ANALYTICS,
      await getSignalEventAnalyticsAgentNode(params)
    )
    .addNode(
      NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
      getSignalEventAnalyticsAgentToolNode(params)
    )
    .addNode(NodeNames.VIN_VIEW, await getVinViewAgentNode(params))
    .addNode(NodeNames.VIN_VIEW_TOOLS, getVinViewAgentToolNode(params))
    .addNode(NodeNames.RESPOND_TO_USER_TOOL, getRespondToUserToolNode(params))
    .addNode(
      NodeNames.CAPTURE_SCREENSHOT,
      await getCaptureScreenshotAgentNode(params)
    )
    .addEdge(START, NodeNames.DOCUMENT_RETRIEVAL)
    .addEdge(NodeNames.DOCUMENT_RETRIEVAL, NodeNames.SUPERVISOR)
    .addConditionalEdges(NodeNames.SUPERVISOR, getNextNode, {
      [NodeNames.RAG]: NodeNames.RAG,
      [NodeNames.REJECT_CLARIFY]: NodeNames.REJECT_CLARIFY,
      [NodeNames.CLAIM_ANALYTICS]: NodeNames.CLAIM_ANALYTICS,
      [NodeNames.SIGNAL_EVENT_ANALYTICS]: NodeNames.SIGNAL_EVENT_ANALYTICS,
      [NodeNames.VIN_VIEW]: NodeNames.VIN_VIEW,
      [NodeNames.RESPOND_TO_USER_TOOL]: NodeNames.RESPOND_TO_USER_TOOL,
      [NodeNames.CAPTURE_SCREENSHOT]: NodeNames.CAPTURE_SCREENSHOT,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addConditionalEdges(NodeNames.RAG, getNextNode, {
      [NodeNames.RESPOND_TO_USER_TOOL]: NodeNames.RESPOND_TO_USER_TOOL,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addConditionalEdges(NodeNames.REJECT_CLARIFY, getNextNode, {
      [NodeNames.RESPOND_TO_USER_TOOL]: NodeNames.RESPOND_TO_USER_TOOL,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.RESPOND_TO_USER_TOOL, END)
    .addConditionalEdges(NodeNames.CLAIM_ANALYTICS, getNextNode, {
      [GenericToolNodeName]: NodeNames.CLAIM_ANALYTICS_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.CLAIM_ANALYTICS_TOOLS, NodeNames.CLAIM_ANALYTICS)
    .addConditionalEdges(NodeNames.SIGNAL_EVENT_ANALYTICS, getNextNode, {
      [GenericToolNodeName]: NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(
      NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
      NodeNames.SIGNAL_EVENT_ANALYTICS
    )
    .addConditionalEdges(NodeNames.VIN_VIEW, getNextNode, {
      [GenericToolNodeName]: NodeNames.VIN_VIEW_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.VIN_VIEW_TOOLS, NodeNames.VIN_VIEW)
    .addConditionalEdges(NodeNames.CAPTURE_SCREENSHOT, getNextNode, {
      [NodeNames.RESPOND_TO_USER_TOOL]: NodeNames.RESPOND_TO_USER_TOOL,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    });

  return stateGraph.compile({ checkpointer });
};

export default getGraph;

import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import {
  listCustomRecordAttributesRequestURI,
  listCustomRecordsCountRequestURI,
} from "./api";

export const useCustomRecordAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listCustomRecordAttributesRequestURI,
    args
  );

export const useListCustomRecordsCount = (
  args: CountRequestWithVehiclesFilter
) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listCustomRecordsCountRequestURI,
    args
  );

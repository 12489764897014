import {
  APIPaginatedRequest,
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListAlertDefinitions = (args: APIPaginatedRequest) =>
  usePaginatedAPI<typeof args, api.AlertDefinition[]>(
    api.listAlertDefinitionRequestURI,
    args
  );

export const useListAlertDefinitionsAttributes = (
  args: ListAttributesRequest
) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listAlertDefinitionsAttributesRequestURI,
    args
  );

export const useListAlertDefinitionsCount = (args: APIPaginatedRequest) =>
  useAPI<typeof args, CountResponse>(
    api.listAlertDefinitionsCountRequestURI,
    args
  );

export const useAlertDefinition = (args: api.AlertDefinitionGetRequestData) =>
  useAPI<typeof args, api.AlertDefinition>(api.getAlertDefinitionURI, args);

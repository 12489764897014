import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
  VehicleAgeTimeline,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import {
  countAssociatedVehiclesRequestURI,
  GetInspectionRequest,
  getInspectionRequestURI,
  getInspectionsByVehicleAgeTimelineRequestURI,
  getInspectionsMetricsHistoryRequestURI,
  getInspectionsMetricsRequestURI,
  getInspectionsTopContributorsRequestURI,
  Inspection,
  InspectionAssociatedSignalEvent,
  InspectionAssociatedSignalEventsRequest,
  InspectionAssociatedVehicle,
  InspectionAssociatedVehiclesRequest,
  InspectionFiltersRequest,
  InspectionsByVehicleAgeAgeRequest,
  InspectionsMetrics,
  InspectionsMetricsHistory,
  InspectionsTopContributor,
  InspectionsTopContributorsRequest,
  listAssociatedSignalEventsRequestURI,
  listAssociatedVehiclesRequestURI,
  listInspectionAttributesRequestURI,
  listInspectionItemAttributesRequestURI,
  listInspectionsCountRequestURI,
  ListInspectionsRequest,
  listInspectionsRequestURI,
} from "./api";

export const useListInspections = (args: ListInspectionsRequest) =>
  usePaginatedAPI<typeof args, Inspection[]>(listInspectionsRequestURI, args);

export const useListInspectionsCount = (args: CountRequestWithVehiclesFilter) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listInspectionsCountRequestURI,
    args
  );

export const useInspection = (args: GetInspectionRequest) =>
  useAPI<typeof args, Inspection>(getInspectionRequestURI, args);

export const useInspectionsMetrics = (args: InspectionFiltersRequest) =>
  useAPI<typeof args, InspectionsMetrics>(
    getInspectionsMetricsRequestURI,
    args
  );

export const useInspectionsMetricsHistory = (args: InspectionFiltersRequest) =>
  useAPI<typeof args, InspectionsMetricsHistory[]>(
    getInspectionsMetricsHistoryRequestURI,
    args
  );

export const useInspectionAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listInspectionAttributesRequestURI,
    args
  );

export const useInspectionItemAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listInspectionItemAttributesRequestURI,
    args
  );

export const useListInspectionsTopContributors = (
  args: InspectionsTopContributorsRequest
) =>
  usePaginatedAPI<typeof args, InspectionsTopContributor[]>(
    getInspectionsTopContributorsRequestURI,
    args
  );

export const useListInspectionsAssociatedVehicles = (
  args: InspectionAssociatedVehiclesRequest
) =>
  usePaginatedAPI<typeof args, InspectionAssociatedVehicle[]>(
    listAssociatedVehiclesRequestURI,
    args
  );

export const useCountInspectionsAssociatedVehicles = (
  args: InspectionAssociatedVehiclesRequest
) =>
  useAPI<typeof args, CountResponse>(countAssociatedVehiclesRequestURI, args);

export const useListInspectionsByVehicleAgeTimeline = (
  args: InspectionsByVehicleAgeAgeRequest
) =>
  useAPI<typeof args, VehicleAgeTimeline[]>(
    getInspectionsByVehicleAgeTimelineRequestURI,
    args
  );

export const useListInspectionsAssociatedSignalEvents = (
  args: InspectionAssociatedSignalEventsRequest
) =>
  usePaginatedAPI<typeof args, InspectionAssociatedSignalEvent[]>(
    listAssociatedSignalEventsRequestURI,
    args
  );

import {
  add,
  differenceInHours,
  formatDistance,
  getTime,
  parseISO,
} from "date-fns";
import { format } from "date-fns-tz";
import { mutate } from "swr";

import { SelectOption } from "features/ui/Select";

import * as config from "config/config";

import {
  CLOSING_BRACKETS,
  DEFAULT_MILEAGE_UNIT,
  DEFAULT_SERVICE_RECORD_TEXT,
  ISO_FORMAT,
  LONG_DATE_FORMAT,
  OPENING_BRACKETS,
  SHORT_DATE_FORMAT,
  TIME_AGO_THRESHOLD_HOURS,
} from "./constants";
import { EventTypeEnum, MileageUnit } from "./types";

// noop does nothing
export const noop = (): void => {};

export const formatPercentValue = (
  ratio: number,
  numDecimalPlaces = 2
): number => Number((ratio * 100).toFixed(numDecimalPlaces));

export const formatPercent = (ratio: number, numDecimalPlaces = 2): string =>
  `${formatPercentValue(ratio, numDecimalPlaces)}%`;

// https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export const toTitleCase = (str: string): string =>
  str
    .replace(/_/g, (_) => " ")
    .replace(
      /(\w)\S*/g,
      (txt: string) =>
        txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    );

// https://www.30secondsofcode.org/js/s/to-snake-case
export const toSnakeCase = (str: string) => {
  return str
    .match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )!
    .map((x) => x.toLowerCase())
    .join("_");
};

// https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
export const toCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const splitAndCapitalizeFirstLetter = (input: string) => {
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const roundToNDecimals = (value: number, decimals: number = 1) => {
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const roundNonZeroToNDecimals = (value: number, decimals: number = 1) =>
  value === 0 ? value : formatNumber(value, decimals);

export const roundPercentageToNDecimals = (
  value: number,
  decimals: number = 1
) => (value === 0 || value === 100 ? value : formatNumber(value, decimals));

export type PopulationLine = {
  key: string;
  color: string;
  label: string;
  dashed: boolean;
};

export const formatNumber = (
  n: number | null,
  numDecimalPlaces = 2,
  minDecimalPlaces = 0
): string | null =>
  n === null
    ? null
    : new Intl.NumberFormat("en-US", {
        maximumFractionDigits: numDecimalPlaces,
        minimumFractionDigits: minDecimalPlaces,
      }).format(n);

export const formatDate = (
  str: string,
  dateFormat: string = LONG_DATE_FORMAT, // April 28, 2022
  ignoreTime: boolean = false,
  timeAgoWhenRecent: boolean = false
): string => {
  // don't format null dates
  if (!str || str === "null") return "";

  try {
    if (
      timeAgoWhenRecent &&
      Math.abs(differenceInHours(new Date(str), new Date())) <
        TIME_AGO_THRESHOLD_HOURS
    ) {
      return formatDistance(new Date(str), new Date(), {
        addSuffix: true,
      });
    }

    const date = ignoreTime ? parseDateOnly(str) : new Date(str);
    if (dateFormat === ISO_FORMAT) {
      return date.toISOString();
    }
    return format(date, dateFormat);
  } catch (err) {
    console.error(err);
    return str;
  }
};

export const parseDateOnly = (str: string): Date => {
  try {
    const matches = str.match(RegExp(/^([0-9]{4})-([0-9]{2})-?([0-9]{2})?.*$/));
    if (matches) {
      const [, year, month, day] = matches;

      // we allow 2023-08 format also, so we need to set day to 1 if it is undefined so that new Date still works
      const dayToUse = day !== undefined ? Number(day) : 1;

      // month is 0-indexed
      return new Date(Number(year), Number(month) - 1, dayToUse);
    }
  } catch (_) {}
  // fallback if parsing year. month and day does not work
  return new Date(str);
};

export const datetimeToTimestamp = (date: string): number =>
  getTime(parseISO(date));

export const extractDateFromDateTime = (str: string): string => {
  try {
    const matches = str.match(RegExp(/^([0-9]{4})-([0-9]{2})-([0-9]{2}).*$/));
    if (matches) {
      const [, year, month, day] = matches;
      return `${year}-${month}-${day}`;
    }
  } catch (_) {}
  // fallback if parsing year. month and day does not work
  return str;
};

export const copyToClipboard = async (str: string): Promise<boolean> => {
  return navigator.clipboard
    .writeText(str)
    .then(
      () => true,
      () => false
    )
    .catch((e) => false);
};

export const wrapInQuotesAndJoin = (
  list: string[] | number[],
  separator = "|"
): string => {
  return list.map((filter) => `"${filter}"`).join(separator);
};

export const pluralize = (
  word: string,
  count?: number,
  pluralForm?: string
) => {
  if (pluralForm) {
    return `${count === 1 ? word : pluralForm}`;
  }
  return `${word}${count === 1 ? "" : "s"}`;
};

export const areArraysEqual = (array1?: any[], array2?: any[]) => {
  if (!array1 || !array2) return false;

  if (array1.length !== array2.length) return false;

  return array1.every((element) => {
    if (!array2.includes(element)) return false;
    return true;
  });
};

export const intersection = (a: any[], b: any[]) => {
  const setA = new Set(a);
  const setB = new Set(b);
  const intersection = new Set([...setA].filter((x) => setB.has(x)));
  return Array.from(intersection);
};

// we set days to 15 to avoid problems with timezones/long months, which date-dns has problems with
// example 1: 2022-01-01 + one month = 2022-01-31.
// example 2: 2022-02-01 00:00:00 + 1 month = 2022-03-01 00:00:00, which gets converted to "2022-02" in this timezone
export function increaseOneMonth(group: string): string {
  return format(
    add(new Date(`${group}-01`).getTime(), { months: 1, days: 15 }),
    "yyyy-MM"
  );
}

export const camelCaseToTitle = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const splitByFirstOccurrence = (value: string, separator: string) => {
  const [first, ...rest] = value.split(separator);
  const remainder = rest.join(separator);

  return [first, remainder];
};

/**
 * Splits a string by a delimiter while ignoring the delimiter
 * - if it is surrounded by double-quotes
 * - if it is surrounded by brackets (parentheses, curly braces or square brackets)
 */
export const splitIgnoringDoubleQuotes = (
  str: string,
  delimiter: string
): string[] => {
  const result = [];
  let insideQuotes = false;
  let insideBrackets = 0;
  let substringStart = 0;
  let isEscaped = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "\\" && !isEscaped) {
      isEscaped = true;
      continue;
    }

    if (char === '"' && !isEscaped) {
      insideQuotes = !insideQuotes;
    } else if (!insideQuotes && OPENING_BRACKETS.includes(char)) {
      insideBrackets++;
    } else if (!insideQuotes && CLOSING_BRACKETS.includes(char)) {
      insideBrackets--;
    }

    if (
      !insideQuotes &&
      insideBrackets === 0 &&
      str.substring(i, i + delimiter.length) === delimiter
    ) {
      result.push(str.substring(substringStart, i));
      substringStart = i + delimiter.length;
      i += delimiter.length - 1;
    }
    isEscaped = false;
  }

  result.push(str.substring(substringStart));

  return result.filter(Boolean);
};

export const getAttributeLabel = (attributeID: string) => {
  return toTitleCase(toSnakeCase(attributeID));
};

export const excludeNulls = <T = string>(items: (T | null)[]): T[] =>
  items.filter((e): e is Exclude<typeof e, null> => e !== null);

export const removeNonDigitCharacters = (str: string): string => {
  return str.replace(/\D/g, "");
};

export const keepNumberBetweenLimits = (
  str: string,
  min: number,
  max: number
): string => {
  const num = Number(str);
  if (isNaN(num) || num < min) return min.toString();
  if (num > max) return max.toString();
  return num.toString();
};

export const cleanString = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9]/g, " ")
    .replace(/\s+/g, " ")
    .trim();
};

export const cloneObject = <T = Record<string, any>>(object: T): T =>
  JSON.parse(JSON.stringify(object));

export const addFormattedDateToOptionsAsLabel = (options: SelectOption[]) =>
  options.map((option) => ({
    ...option,
    label: formatDate(option.value.toString(), SHORT_DATE_FORMAT, true),
  }));

export const randomID = () =>
  typeof crypto !== "undefined" && crypto.randomUUID
    ? crypto.randomUUID()
    : `${new Date().getTime()}${Math.random().toString(36).substring(2)}`;

export const getTenantServiceRecordName = (pluralForm: boolean = true) => {
  const {
    pages: { events },
  } = config.get();
  const serviceRecordText =
    events?.serviceRecordText ?? DEFAULT_SERVICE_RECORD_TEXT;
  return pluralForm ? pluralize(serviceRecordText, 3) : serviceRecordText;
};

export const getTenantMileageUnit = (): MileageUnit => {
  const {
    pages: { global },
  } = config.get();
  return global?.mileageUnit ?? DEFAULT_MILEAGE_UNIT;
};

export const mutateMultipleSWRRequestKeys = (requestKeys: string[]) =>
  mutate((key: string) => requestKeys.includes(key));

export const joinWithCommasAndAnd = (items: string[]) => {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0];
  return items.slice(0, -1).join(", ") + " and " + items.slice(-1);
};

export const isNumeric = (n: any) => {
  if (typeof n === "string") {
    if (n === "0") {
      return true;
    }
    if (Number.isInteger(parseFloat(n))) {
      return (
        !isNaN(parseFloat(n)) &&
        isFinite(n as any) &&
        n.toString().indexOf("0") !== 0
      );
    }
    return !isNaN(parseFloat(n)) && isFinite(n as any);
  }
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getEntityWithExternalIDTitle = (
  externalID?: string | null,
  entity?: EventTypeEnum
) => {
  const title = `${camelCaseToTitle(entity || "")} detail`;
  if (!externalID) {
    return title;
  }

  return `${title}: ${externalID}`;
};

export const currencyUnitToCurrencySign = (
  unit?: "USD" | "EUR" | null
): string => {
  switch (unit) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    default:
      return "";
  }
};

export const currencyValue = (
  currencyCode: "USD" | "EUR" | null,
  value: number | null
) => {
  if (!currencyCode || !value) return "";
  return `${currencyUnitToCurrencySign(currencyCode)}${formatNumber(value)}`;
};

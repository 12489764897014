import { useEffect, useRef, useState } from "react";
import { AvailableData, PageState } from "duck/graph/types";
import qs from "qs";
import { createPath, useNavigate } from "react-router";

import { TAB_QUERY_PARAM } from "shared/constants";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { useGroupBySelectOptions } from "shared/schemas/hooks";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import { Resource } from "shared/schemas/types";
import { randomID } from "shared/utils";

import { useByVehicleAgeChartActions as useClaimAnalyticsByVehicleAgeChartActions } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/hooks";
import { useClaimsChartActions } from "pages/ClaimAnalytics/tabPages/Claims/hooks";
import { useTopContributorsChartYAxisOptions } from "pages/ClaimAnalytics/tabPages/TopContributors/hooks";
import { useTopContributorsExposureOptions } from "pages/hooks";
import { useGetByVehicleAgeChartActions as useSignalEventAnalyticsByVehicleAgeChartActions } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/hooks";
import { getSignalEventChartActions } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/utils";
import { topContributorsChartYAxisOptions } from "pages/SignalEventsAnalytics/tabPages/TopContributors/ChartActions";

import { WINDOW_DIRECTION_OPTIONS } from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import { SelectOption } from "features/ui/Select";

import { LANGCHAIN_THREAD_ID_KEY } from "./constants";
import { LocationInfo, QueryStringNavigation, Reload } from "./types";
import {
  assertNonEmptyStringArray,
  createExposureHierarchy,
  getByVehicleAgeChartOptionStrings,
  getClaimsChartOptionStrings,
  getInitialThreadId,
  getInitialVisibility,
  getPageState,
  getSignalEventsChartOptionStrings,
  getVinViewAgentData,
  persistVisibility,
  toNonEmptyStringArray,
} from "./utils";

/**
 * The useGroupBySelectOptions hook temporarily returns an empty array while the data loads.
 * This wrapper hook ensures that the array is never empty by putting a placeholder in it.
 */
const useNonEmptyGroupBySelectOptions = (
  resource: Resource,
  skipVehicleAttributes?: boolean
): SelectOption[] => {
  const groupBySelectOptions = useGroupBySelectOptions(
    resource,
    skipVehicleAttributes
  );
  if (!groupBySelectOptions || groupBySelectOptions.length === 0) {
    return [
      {
        id: "placeholder",
        value: "Placeholder While Data Loads",
      },
    ];
  }

  return groupBySelectOptions;
};

const useClaimAnalyticsAgentData = (): AvailableData["claimAnalytics"] => {
  const claimsChartOptions = useClaimsChartActions();
  const byVehicleAgeChartActions = useClaimAnalyticsByVehicleAgeChartActions();
  const groupBySelectOptions = useNonEmptyGroupBySelectOptions("claim");

  const topContributorsYAxisOptions = useTopContributorsChartYAxisOptions();

  const { attributes } = useClaimsSchema();

  const topContributorsExposures = useTopContributorsExposureOptions("claim");

  const exposuresWithBuckets = createExposureHierarchy(
    topContributorsExposures,
    attributes
  );

  return {
    claimsChartOptions: getClaimsChartOptionStrings(claimsChartOptions),
    byVehicleAgeChartOptions: getByVehicleAgeChartOptionStrings(
      byVehicleAgeChartActions
    ),
    topContributorsGroupByOptions: toNonEmptyStringArray(groupBySelectOptions),
    topContributorsChartOptions: {
      y: toNonEmptyStringArray(topContributorsYAxisOptions),
      exposure: exposuresWithBuckets,
    },
  };
};

const useSignalEventsAnalyticsAgentData =
  (): AvailableData["signalEventAnalytics"] => {
    const signalEventsActions = getSignalEventChartActions();
    const byVehicleAgeActions =
      useSignalEventAnalyticsByVehicleAgeChartActions();
    const topContributorsExposures = useTopContributorsExposureOptions(
      "signalEventOccurrence"
    );
    const { attributes } = useSignalEventOccurrencesSchema();
    const topContributorsGroupBySelectOptions = useNonEmptyGroupBySelectOptions(
      "signalEventOccurrence"
    );
    const associatedClaimsGroupBySelectOptions =
      useNonEmptyGroupBySelectOptions("claim", true);

    const associatedSignalEventsWindowDirectionOptions =
      WINDOW_DIRECTION_OPTIONS.map(
        (windowDirectionOption) => windowDirectionOption.id
      );
    assertNonEmptyStringArray(associatedSignalEventsWindowDirectionOptions);

    return {
      signalEventsChartOptions:
        getSignalEventsChartOptionStrings(signalEventsActions),
      byVehicleAgeChartOptions:
        getByVehicleAgeChartOptionStrings(byVehicleAgeActions),
      topContributorsChartOptions: {
        y: toNonEmptyStringArray(topContributorsChartYAxisOptions),
        exposure: createExposureHierarchy(topContributorsExposures, attributes),
      },
      topContributorsGroupByOptions: toNonEmptyStringArray(
        topContributorsGroupBySelectOptions
      ),
      associatedClaimsGroupByOptions: toNonEmptyStringArray(
        associatedClaimsGroupBySelectOptions
      ),
      associatedSignalEventsWindowDirectionOptions,
    };
  };

/**
 * This hook assembles the data that the Duck agent needs in order to do its work.
 * For now, all of this data is related to the claim analytics page.
 *
 * Most of the data is relatively static, and is provided at the time the hook runs.
 * The hook also provides a getPageState function that can be called at the time
 * the agent is called in order to get the dynamic data that is needed.
 */
export const useAgentData = (): {
  availableData: AvailableData;
  getPageState: () => PageState;
} => {
  const [vinView, setVinView] = useState<AvailableData["vinView"] | null>(null);
  const claimAnalytics = useClaimAnalyticsAgentData();
  const signalEventAnalytics = useSignalEventsAnalyticsAgentData();

  useEffect(() => {
    const fetchData = async () => {
      const retrievedVinView = await getVinViewAgentData();

      setVinView(retrievedVinView);
    };

    fetchData();
  }, []);

  return {
    availableData: {
      claimAnalytics,
      signalEventAnalytics,
      vinView,
    },
    getPageState,
  };
};

export const useDuckVisibility = (forceOpen?: boolean) => {
  const [open, setOpen] = useState(forceOpen || getInitialVisibility());

  if (forceOpen) {
    persistVisibility(true);
  }

  const setIsDuckVisible = (visible: boolean) => {
    setOpen(visible);
    persistVisibility(visible);
  };

  return { isDuckVisible: open, setIsDuckVisible };
};

/**
 * @summary This hook provides a thread id for the Duck agent, and also provides
 * a mechanism to reset it. Resetting the memory of the Duck session is accomplished
 * by resetting the thread id.
 * @returns The current thread id and a function to reset it.
 */
export const useThreadId = () => {
  const [threadId, setThreadId] = useState(getInitialThreadId());

  const resetThreadId = () => {
    const updatedThreadId = randomID();
    if (sessionStorage) {
      sessionStorage.setItem(LANGCHAIN_THREAD_ID_KEY, updatedThreadId);
    }
    setThreadId(updatedThreadId);
  };

  return { threadId, resetThreadId };
};

/**
 * When using this hook, we must be sure that the hook does not get
 * recreated based on its location in the component tree. If it does, the agent
 * would be working with a disconnected instance of the hook that does not
 * actually do anything.
 * A simple solution is to use the hook on a component high in the hierarchy
 * that is not likely to be re-rendered often.
 */
export const useQueryStringNavigation = (): QueryStringNavigation => {
  const navigate = useNavigate();
  const reloadRequiredRef = useRef<Reload>(Reload.NONE);

  const [updatedQueryParams, setUpdatedQueryParams] = useState<
    Record<string, string>
  >({});

  // The routeValue corresponds to the page in the app
  const [routeValue, setRouteValue] = useState("");

  const deliverLocationInfo = (reset: boolean = true): LocationInfo => {
    const priorQueryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const newQueryParams = { ...priorQueryParams, ...updatedQueryParams };

    const path = createPath({
      pathname: routeValue,
      search: qs.stringify(newQueryParams, { arrayFormat: "indices" }),
      hash: window.location.hash,
    });

    const response = {
      reloadRequired: reloadRequiredRef.current,
      path,
      url: `${window.location.origin}${path}`,
    };

    if (reset) {
      clearLocationInfo();
    }

    return response;
  };

  /**
   * Clear the data managed by this utility.
   */
  const clearLocationInfo = () => {
    setUpdatedQueryParams({});
    reloadRequiredRef.current = Reload.NONE;
  };

  const updateLocation = (): void => {
    const { path, url, reloadRequired } = deliverLocationInfo();
    if (reloadRequired === Reload.HARD) {
      window.location.assign(url);
    } else if (reloadRequired === Reload.SOFT) {
      navigate(path);
    }
  };

  const updateQueryStringParameter = (
    paramName: string,
    paramValue: string,
    reload: Reload = Reload.NONE
  ): void => {
    setUpdatedQueryParams((prev) => ({ ...prev, [paramName]: paramValue }));

    if (reload === Reload.HARD) {
      reloadRequiredRef.current = Reload.HARD;
    } else if (
      reload === Reload.SOFT &&
      reloadRequiredRef.current === Reload.NONE
    ) {
      reloadRequiredRef.current = Reload.SOFT;
    }

    console.log(
      `${new Date().getTime()} set query string parameter "${paramName}" to "${paramValue}"`
    );
  };

  const navigateToTab = (tabId: string) => {
    updateQueryStringParameter(TAB_QUERY_PARAM, tabId, Reload.SOFT);
  };

  const updateFilter = (
    filterQueryString: string,
    queryStringParameterName: string
  ): void => {
    updateQueryStringParameter(
      queryStringParameterName,
      filterQueryString,
      Reload.HARD
    );
  };

  return {
    deliverLocationInfo,
    updateLocation,
    clearLocationInfo,
    setRouteValue,
    updateQueryStringParameter,
    navigateToTab,
    updateFilter,
  };
};

/* Routing tools definitions
 * These are the definitions for the routing tools that are used to route the conversation to the appropriate node.
 * These tools do not get executed in a tool node but rather used in the conditional edge logic.
 */
import { rejectClarifyValidActions } from "duck/graph/nodes/RejectClarifyAgent/getNode";
import { ToolCallRoutableNodeNames } from "duck/graph/utils";

export const routeToRejectClarifyToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.REJECT_CLARIFY,
    description:
      "Route to the agent that rejects or clarifies the user's request",
    parameters: {
      type: "object",
      properties: {
        action: {
          type: "string",
          enum: rejectClarifyValidActions,
          description: "Action to perform: reject or clarify.",
        },
        reason: {
          type: "string",
          description: "Reason for the action.",
        },
      },
      required: ["action", "reason"],
      additionalProperties: false,
    },
  },
} as const;

export const routeToClaimAnalyticsToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.CLAIM_ANALYTICS,
    description: "Route to the Claim Analytics agent",
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToSignalEventAnalyticsToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.SIGNAL_EVENT_ANALYTICS,
    description: "Route to the Signal Event Analytics agent",
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToVinViewToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.VIN_VIEW,
    description: "Route to the Vin View agent",
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToRagToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.RAG,
    description: "Route to the RAG agent",
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToCaptureScreenshotToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.CAPTURE_SCREENSHOT,
    description: "Route to the Capture Screenshot agent",
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const reportToSupervisorToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.SUPERVISOR,
    description: "Report to the supervisor with a list of actions taken",
    parameters: {
      type: "object",
      required: ["report"],
      strict: true,
      properties: {
        report: {
          type: "array",
          description: "List of actions taken in full detail",
          items: {
            type: "string",
            description: "full detail of the action taken",
          },
        },
      },
      additionalProperties: false,
    },
  },
} as const;

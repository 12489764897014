import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  invokeAgentNode,
  NodeType,
} from "duck/graph/nodes/utils";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import {
  routeToCaptureScreenshotToolDef,
  routeToClaimAnalyticsToolDef,
  routeToRagToolDef,
  routeToRejectClarifyToolDef,
  routeToSignalEventAnalyticsToolDef,
  routeToVinViewToolDef,
} from "duck/graph/tools/routingTools";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.SUPERVISOR_AGENT);
  const llm = getLLM();
  const tools = [
    getRespondToUserTool(
      params.uiHandlers.setAgentResponse,
      params.uiHandlers.setEphemeralMessage
    ),
    routeToClaimAnalyticsToolDef,
    routeToRagToolDef,
    routeToCaptureScreenshotToolDef,
    routeToRejectClarifyToolDef,
    routeToSignalEventAnalyticsToolDef,
    routeToVinViewToolDef,
  ];
  // disable parallel tool calls since we currently don't want to route to multiple agents
  const agent = createStrictToolCallingAgent(llm, tools, prompt, false);

  return invokeAgentNode(
    agent,
    undefined,
    NodeNames.SUPERVISOR,
    params.uiHandlers.setEphemeralMessage
  );
};

export default getNode;

import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useFailureModes = (args: api.ListFailureModesRequest) =>
  usePaginatedAPI<typeof args, api.FailureMode[]>(
    api.listFailureModesRequestURI,
    args
  );

export const useFailureMode = (args: api.GetFailureModeRequest) =>
  useAPI<typeof args, api.FailureMode>(api.getFailureModeRequestURI, args);

export const useFailureModesCount = (args: api.ListFailureModesRequest) =>
  useAPI<typeof args, CountResponse>(api.countFailureModesRequestURI, args);

export const useFailureModeAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listFailureModeAttributesRequestURI,
    args
  );

export const useFailureModeRiskModel = (args: api.GetFailureModeRequest) =>
  useAPI<typeof args, api.RiskModel>(
    api.getFailureModeRiskModelRequestURI,
    args
  );

export const useFailureModeEventsCount = (
  args: api.ListFailureModesEventsRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.getFailureModeEventsCountRequestURI,
    args
  );

export const useFailureModeEvents = (args: api.ListFailureModesEventsRequest) =>
  usePaginatedAPI<typeof args, api.FailureModeEvent[]>(
    api.listFailureModeEventsRequestURI,
    args
  );

export const useFailureModeEventsTimeline = (
  args: api.ListFailureModesEventsRequest
) =>
  useAPI<typeof args, api.FailureModeEventsTimeline>(
    api.getFailureModeEventsTimelineURI,
    args
  );

export const useFailureModeRiskModelPredictions = (
  args: api.ListFailureModesPredictionsRequest
) =>
  usePaginatedAPI<typeof args, api.RiskModelPrediction[]>(
    api.listFailureModeRiskModelPredictionsRequestURI,
    args
  );

export const useFailureModeRiskModelPredictionsCount = (
  args: api.ListFailureModesPredictionsRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.listFailureModeRiskModelPredictionsCountRequestURI,
    args
  );

export const useRiskModelPredictionAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listRiskModelPredictionAttributesRequestURI,
    args
  );

export const useVehicleRiskModelPredictions = (
  args: api.ListVehicleRiskModelPredictionsRequest
) =>
  usePaginatedAPI<typeof args, api.RiskModelPrediction[]>(
    api.listVehicleRiskModelPredictionsRequestURI,
    args
  );

export const useVehicleRiskModelPredictionsCount = (
  args: api.ListVehicleRiskModelPredictionsRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.listVehicleRiskModelPredictionsCountRequestURI,
    args
  );

export const useFailureModeEventsAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listFailureModeEventsAttributesRequestURI,
    args
  );

import { SelectOption } from "features/ui/Select";

export const enum Granularity {
  WEEK = "week",
  MONTH = "month",
}

export const ageGranularityOptions: SelectOption[] = [
  {
    id: Granularity.WEEK,
    value: "Week",
  },
  {
    id: Granularity.MONTH,
    value: "Month",
  },
];
